import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/home/runner/work/prof/prof/deps/docs/src/components/AutoDocLayout.jsx";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h2>{`Name`}</h2>
    <p><strong parentName="p">{`step certificate sign`}</strong>{` -- sign a certificate signing request (CSR)`}</p>
    <h2>{`Usage`}</h2>
    <pre><code parentName="pre" {...{
        "className": "language-raw"
      }}>{`step certificate sign <csr-file> <crt-file> <key-file>
[--profile=<profile>] [--template=<file>] 
[--set=<key=value>] [--set-file=<file>]
[--password-file=<file>] [--path-len=<maximum>]
[--not-before=<time|duration>] [--not-after=<time|duration>]
[--bundle]
`}</code></pre>
    <h2>{`Description`}</h2>
    <p><strong parentName="p">{`step certificate sign`}</strong>{` generates a signed
certificate from a certificate signing request (CSR).`}</p>
    <h2>{`Positional arguments`}</h2>
    <p><inlineCode parentName="p">{`csr-file`}</inlineCode>{`
The path to a certificate signing request (CSR) to be signed.`}</p>
    <p><inlineCode parentName="p">{`crt-file`}</inlineCode>{`
The path to an issuing certificate.`}</p>
    <p><inlineCode parentName="p">{`key-file`}</inlineCode>{`
The path to a private key for signing the CSR.`}</p>
    <h2>{`Options`}</h2>
    <p><strong parentName="p">{`--kms`}</strong>{`=`}<inlineCode parentName="p">{`uri`}</inlineCode>{`
The `}<inlineCode parentName="p">{`uri`}</inlineCode>{` to configure a Cloud KMS or an HSM.`}</p>
    <p><strong parentName="p">{`--profile`}</strong>{`=`}<inlineCode parentName="p">{`profile`}</inlineCode>{`
The certificate profile sets various certificate details such as
certificate use and expiration. The default profile is 'leaf' which is suitable
for a client or server using TLS.`}</p>
    <p><inlineCode parentName="p">{`profile`}</inlineCode>{` is a case-sensitive string and must be one of:`}</p>
    <ul>
      <li parentName="ul">
        <p parentName="li"><strong parentName="p">{`leaf`}</strong>{`: Signs a leaf x.509 certificate suitable for use with TLS.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li"><strong parentName="p">{`intermediate-ca`}</strong>{`: Signs a certificate that can be used to sign additional leaf certificates.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li"><strong parentName="p">{`csr`}</strong>{`: Signs a x.509 certificate without modifying the CSR.`}</p>
      </li>
    </ul>
    <p><strong parentName="p">{`--template`}</strong>{`=`}<inlineCode parentName="p">{`file`}</inlineCode>{`
The certificate template `}<inlineCode parentName="p">{`file`}</inlineCode>{`, a JSON representation of the certificate to create.`}</p>
    <p><strong parentName="p">{`--set`}</strong>{`=`}<inlineCode parentName="p">{`key=value`}</inlineCode>{`
The `}<inlineCode parentName="p">{`key=value`}</inlineCode>{` pair with template data variables. Use the `}<strong parentName="p">{`--set`}</strong>{` flag multiple times to add multiple variables.`}</p>
    <p><strong parentName="p">{`--set-file`}</strong>{`=`}<inlineCode parentName="p">{`file`}</inlineCode>{`
The JSON `}<inlineCode parentName="p">{`file`}</inlineCode>{` with the template data variables.`}</p>
    <p><strong parentName="p">{`--password-file`}</strong>{`=`}<inlineCode parentName="p">{`file`}</inlineCode>{`
The path to the `}<inlineCode parentName="p">{`file`}</inlineCode>{` containing the password to encrypt or decrypt the private key.`}</p>
    <p><strong parentName="p">{`--not-before`}</strong>{`=`}<inlineCode parentName="p">{`time|duration`}</inlineCode>{`
The `}<inlineCode parentName="p">{`time|duration`}</inlineCode>{` set in the NotBefore property of the certificate. If a
`}<inlineCode parentName="p">{`time`}</inlineCode>{` is used it is expected to be in RFC 3339 format. If a `}<inlineCode parentName="p">{`duration`}</inlineCode>{` is
used, it is a sequence of decimal numbers, each with optional fraction and a
unit suffix, such as "300ms", "-1.5h" or "2h45m". Valid time units are "ns",
"us" (or "µs"), "ms", "s", "m", "h".`}</p>
    <p><strong parentName="p">{`--not-after`}</strong>{`=`}<inlineCode parentName="p">{`time|duration`}</inlineCode>{`
The `}<inlineCode parentName="p">{`time|duration`}</inlineCode>{` set in the NotAfter property of the certificate. If a
`}<inlineCode parentName="p">{`time`}</inlineCode>{` is used it is expected to be in RFC 3339 format. If a `}<inlineCode parentName="p">{`duration`}</inlineCode>{` is
used, it is a sequence of decimal numbers, each with optional fraction and a
unit suffix, such as "300ms", "-1.5h" or "2h45m". Valid time units are "ns",
"us" (or "µs"), "ms", "s", "m", "h".`}</p>
    <p><strong parentName="p">{`--path-len`}</strong>{`=`}<inlineCode parentName="p">{`maximum`}</inlineCode>{`
The `}<inlineCode parentName="p">{`maximum`}</inlineCode>{` path length to set in the pathLenConstraint of an intermediate-ca.
Defaults to 0. If it's set to -1 no path length limit is imposed.`}</p>
    <p><strong parentName="p">{`--bundle`}</strong>{`
Bundle the new leaf certificate with the signing certificate.`}</p>
    <h2>{`Exit codes`}</h2>
    <p>{`This command returns 0 on success and >0 if any error occurs.`}</p>
    <h2>{`Examples`}</h2>
    <p>{`Sign a certificate signing request using the leaf profile:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-shell"
      }}>{`$ step certificate sign leaf.csr issuer.crt issuer.key
# or
$ step certificate sign --profile leaf leaf.csr issuer.crt issuer.key
`}</code></pre>
    <p>{`Sign a CSR and bundle the new certificate with the issuer:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-shell"
      }}>{`$ step certificate sign --bundle leaf.csr issuer.crt issuer.key
`}</code></pre>
    <p>{`Sign a CSR with custom validity and bundle the new certificate with the issuer:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-shell"
      }}>{`$ step certificate sign --bundle --not-before -1m --not-after 16h leaf.csr issuer.crt issuer.key
`}</code></pre>
    <p>{`Sign an intermediate ca:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-shell"
      }}>{`$ step certificate sign --profile intermediate-ca intermediate.csr issuer.crt issuer.key
`}</code></pre>
    <p>{`Sign an intermediate ca that can sign other intermediates; in this example, the
issuer must set the pathLenConstraint at least to 2 or without a limit:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-shell"
      }}>{`$ step certificate sign --profile intermediate-ca --path-len 1 intermediate.csr issuer.crt issuer.key
`}</code></pre>
    <p>{`Sign a CSR but only use information present in it, it doesn't add any key or
extended key usages if they are not in the CSR.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-shell"
      }}>{`$ step certificate sign --profile csr test.csr issuer.crt issuer.key
`}</code></pre>
    <p>{`Sign a CSR with only clientAuth as key usage using a template:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-shell"
      }}>{`$ cat coyote.tpl
{
  "subject": {
    "country": "US",
        "organization": "Coyote Corporation",
        "commonName": "{{ .Subject.CommonName }}"
  },
  "emailAddresses": {{ toJson .Insecure.CR.EmailAddresses }},
  "keyUsage": ["digitalSignature"],
  "extKeyUsage": ["clientAuth"]
}
$ step certificate create --csr coyote@acme.corp coyote.csr coyote.key
$ step certificate sign --template coyote.tpl coyote.csr issuer.crt issuer.key
`}</code></pre>
    <p>{`Sign a CSR using a template and allow configuring the subject using the
`}<strong parentName="p">{`--set`}</strong>{` and `}<strong parentName="p">{`--set-file`}</strong>{` flags.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-shell"
      }}>{`$ cat rocket.tpl
{
  "subject": {
    "country": {{ toJson .Insecure.User.country }},
    "organization": {{ toJson .Insecure.User.organization }},
    "organizationalUnit": {{ toJson .Insecure.User.organizationUnit }},
    "commonName": {{toJson .Subject.CommonName }}
  },
  "sans": {{ toJson .SANs }},
{{- if typeIs "*sa.PublicKey" .Insecure.CR.PublicKey }}
  "keyUsage": ["keyEncipherment", "digitalSignature"],
{{- else }}
  "keyUsage": ["digitalSignature"],
{{- end }}
  "extKeyUsage": ["serverAuth", "clientAuth"]
}
$ cat organization.json
{
  "country": "US",
  "organization": "Acme Corporation",
  "organizationUnit": "HQ"
}
$ step certificate create --csr rocket.acme.corp rocket.csr rocket.key
$ step certificate sign --template rocket.tpl \\
  --set-file organization.json --set organizationUnit=Engineering \\
  rocket.csr issuer.crt issuer.key
`}</code></pre>
    <p>{`Sign a CSR using `}<inlineCode parentName="p">{`step-kms-plugin`}</inlineCode>{`:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-shell"
      }}>{`$ step certificate sign \\
  --kms 'pkcs11:module-path=/usr/local/lib/softhsm/libsofthsm2.so;token=smallstep?pin-value=password' \\
  leaf.csr issuer.crt 'pkcs11:id=4001'
`}</code></pre>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      